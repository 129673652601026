html {
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  margin: 0;
  user-select: none;
}

#root {
  min-height: 100%;
}